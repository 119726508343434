import { useState, useEffect } from 'react'
import Image from 'next/image';
import Link from 'next/link';
import styles from '../../styles/Home.module.css';
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router';
import $ from 'jquery';

const SearchBar = dynamic(() => import('../Search/SearchBar'), { ssr: false })

const MainHero = ({contentObj, prompt}) => {
  const content = contentObj.content;
  const contentID = contentObj.id;
  const backgroundType = content[0].BackgroundMedia.data?.attributes.mime.split('/').shift();
  const router = useRouter();
  const [showSearch, setShowSearch] = useState(false);
  const searchPages = ['/stories', '/search'];

  useEffect( () => {
    if (searchPages.includes(router.pathname)) {
      setShowSearch(true);
    }
  }, [router]);

  useEffect(() => {
    $('.explore').each(function() {
      $(this).on('click', function () {
          var nextSection = $(this).parent().next();
          $('html, body').animate({
              scrollTop: nextSection.offset().top + 1
          }, 1000 );
      });
    });
  })

  return (
    <section id="home-hero" className={`${styles.hero_bg} w-full relative`}>
      <div className={`${styles.home_wrapper} ${backgroundType == 'video' && styles.main_video_hero } hero-bg bg-fixed bg-no-repeat bg-cover h-full mx-auto flex px-5 py-24 items-center justify-center flex-col`}>
        <style jsx>{`
          .hero-bg {
            ${backgroundType == 'image' && `background-image: url(${content[0].BackgroundMedia.data.attributes.url}) rgb(74,140,176);`}
            ${backgroundType == 'image' && `background-image: url(${content[0].BackgroundMedia.data.attributes.url}),`} -moz-linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
            ${backgroundType == 'image' && `background-image: url(${content[0].BackgroundMedia.data.attributes.url}),`} -webkit-linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
            ${backgroundType == 'image' && `background-image: url(${content[0].BackgroundMedia.data.attributes.url}),`} linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
          }
        `}</style>
        {backgroundType == 'image' &&
          <video autoPlay loop className={`${styles.main_video}`}>
            <source src={content[0].BackgroundMedia.data.attributes.url} type='video/mp4'/>
          </video>
        }
        {backgroundType == 'video' &&
          <video autoPlay loop className={`${styles.main_video}`}>
            <source src={content[0].BackgroundMedia.data.attributes.url} type='video/mp4'/>
          </video>
        }
        <div className="text-center w-full">
          {content[0].Headline && <h1 className="title-font text-white mb-3 font-large uppercase">{content[0].Headline}</h1>}
          {prompt && <h2 className={`title-font text-white mb-3 font-large uppercase ${styles.prompt}`}>{prompt}</h2>}
          {content[0].Subtext && <p className="mb-10 text-white">{content[0].Subtext}</p>}
          {content[0].Button?.Text &&
            <div className="flex justify-center">
              <Link href={content[0].Button?.URL}>
                <a className="inline-flex content-between text-white btn-orange border-0 btn-padding focus:outline-none text-lg w-svg">{content[0].Button.Text} 
                  <svg width="25.032" height="21.342" viewBox="0 0 25.032 21.342">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_29" data-name="Rectangle 29" width="21.342" height="25.032" fill="#fff"/>
                      </clipPath>
                    </defs>
                    <g id="Group_49" data-name="Group 49" transform="translate(25.032) rotate(90)">
                      <path id="Path_85" data-name="Path 85" d="M10.661,0,0,10.622l.824.827,9.254-9.221L10.1,22.51h1.167L11.246,2.234l9.271,9.259.825-.826Z" transform="translate(0)" fill="#fff"/>
                      <g id="Group_34" data-name="Group 34" transform="translate(0 0)">
                        <g id="Group_33" data-name="Group 33" clipPath="url(#clip-path)">
                          <path id="Path_86" data-name="Path 86" d="M42.483,100.261a.841.841,0,1,0,1.189,0,.84.84,0,0,0-1.189,0" transform="translate(-32.376 -76.664)" fill="#fff"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </Link>
            </div>
          }
          {showSearch &&
            <div className="pt-1 relative mx-auto text-gray-600">
              <SearchBar />
            </div>
          }
        </div>
      </div>
      <div className="explore text-white lato-title text-center">
        <Link href="#">
          <a>
            <h4 className="mb-1 uppercase">Explore</h4>
            <svg width="12.015" height="19.787" viewBox="0 0 12.015 19.787">
              <path id="Path_1" data-name="Path 1" d="M20.538,31.94l8.833-8.833-2.944-2.944-5.888-5.888" transform="translate(-19.477 -13.214)" fill="none" stroke="#fff" strokeWidth="3"/>
            </svg>
          </a>
        </Link>
      </div>
    </section>
  )
}

export default MainHero;